<template>
  <div class="row">
    <div class="col-md-12 bannerContainer">
      <article class="single-blog post-list">
        <hide-at breakpoint="'smallAndBelow'">
          <a @click.stop="$router.push({name: 'Vsa'})">
            <img
                :src="require('@/assets/images/promo/banner_vsa_2025.jpg')"
                alt="Vseslovenska akcija zbiranja zapisov"
                class="img-responsive w-100"
                style="cursor: pointer;"
            />
          </a>
        </hide-at>
        <show-at breakpoint="'small'">
          <a @click.stop="$router.push({name: 'Vsa'})">
            <img
                :src="require('@/assets/images/promo/banner_vsa_2025.jpg')"
                alt="Vseslovenska akcija zbiranja zapisov"
                class="img-responsive w-100"
                style="cursor: pointer;"
            />
          </a>
        </show-at>
      </article>
    </div>
  </div>
</template>

<script>
import {showAt, hideAt} from 'vue-breakpoints';

export default {
  name: "BannerVsa",
  components: {
    hideAt,
    showAt
  },
  props: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {
  },
  methods: {
    openLinkInNewTab(url) {
      setTimeout(() => {
        Object.assign(document.createElement("a"), {
          target: "_blank",
          href: url
        }).click();
      }, 10);
    }
  }
};
</script>
<style type="scss">
.bannerContainer {
  transition: all 0.3s ease;
  transform: scale(1.0);
  filter: saturate(1.0);
}

.bannerContainer:hover {
  cursor: pointer !important;
  transform: scale(1.03);
  filter: saturate(1.2);
}
</style>
